import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import JobInputForm from '../components/jobInputForm/jobInputForm';

const NewJobPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h4" gutterBottom>
        Create a New Processing Job
      </Typography>
      <Typography variant="body1" gutterBottom>
        This page allows you to create a new processing job in the application. You can provide the necessary details and settings for the job through the form below.
      </Typography>
      <Box mt={4}>
        <JobInputForm />
        {/* <NewJobForm placeholder="Placeholder text for the form" /> */}
      </Box>
    </Container>
  );
};

export default NewJobPage;

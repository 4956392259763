import { Box, Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import SectionCard from '../components/sectionCard/sectionCard';
import { OverallCurrentPage } from '../context';

const HomePage = ({
}) => {

    const { setCurrentPage } = React.useContext(OverallCurrentPage)

    return (
        <Box>
            <Card
                elevation={10}
                sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    my: 4,
                    py: 8,
                    textAlign: 'center',
                    borderRadius: '15px',
                    //   boxShadow: '5px 5px 0px 0px rgba(0,0,200,0.3)',
                }}
            >
                <Typography variant="h2" component="h1" gutterBottom>
                    Meetings agent
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Questa applicazione permette di creare automaticamente riassunti di riunioni a partire dalla loro trascrizione.<br />

                    L'applicazione è in beta preview e non ha ancora tutte le funzionalità. Richieste e suggerimenti sono fortemente graditi.
                </Typography>
                <Button variant="contained" color="secondary" sx={{ mt: 4 }} onClick={() => setCurrentPage('jobInput')}>
                    Quick start
                </Button>
            </Card>

            <Box sx={{ py: 6, px: 6 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Come contribuire?" imagePath="/imgs/sharing_ideas.png">
                        Se hai segnalazioni o richieste apri una <a href="https://github.com/kireply/MeetingsAgent/issues" target='_blank'>issue</a> sul repository del progetto.
                        </SectionCard>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Summarizzazione" description={
                            "Se hai segnalazioni o richieste apri una"
                        } imagePath="/imgs/summary.png">
                        <Typography variant="body1">
                                Puoi creare <a href="#" onClick={() => setCurrentPage('newJob')}>qui</a> la tua richiesta di riassunto.<br />
                            </Typography>
                            <Typography variant="body1">
                                Oppure puoi verificare i lavori che hai già richiesto <a href="#" onClick={() => setCurrentPage('jobList')}>qui</a>.
                            </Typography>
                        </SectionCard>
                    </Grid>
                    {/* <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Progetti" imagePath="/imgs/projects.png">
                        <Typography variant="body1">
                                Da qui potrai gestire i progetti. <br />
                            </Typography>
                            <Typography variant="body1">
                                Un progetto raccoglie materiale e persone e rende più facile la collaborazione.<br />
                            </Typography>
                            <Typography color="error" variant="body1">
                                Funzionalità non ancora disponibile.
                            </Typography>
                        </SectionCard>
                    </Grid> */}
                </Grid>
                
            </Box>
        </Box>
    );
};

export default HomePage;


import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { OverallCurrentPage, User } from '../../context';
import ApiClient from '../../services/api/api';
import { uploadFileToS3ByPresignedUrl } from '../../services/aws/s3/s3';
// import ProjectService from '../../services/projects/projectService';
import ProjectDirectoryClient from "../../services/api/project_api.js";
import UserService from '../../services/users/userService';

export default function JobInputForm(props) {
    const [selectedProject, setSelectedProject] = React.useState('Nessuno');
    const [selectedTemplate, setSelectedTemplate] = React.useState("template_EN.md");
    const [ccValues, setCcValues] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [file, setFile] = React.useState(null);
    const userSvc = React.useMemo(() => new UserService(), []);
    // const projSvc = React.useMemo(() => new ProjectService(), []);
    const {setCurrentPage} = React.useContext(OverallCurrentPage);
    const [projects, setProjects] = React.useState([]);
    const [ccEmails, setCCEmails] = React.useState([]);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [jobSubmitStep, setJobSubmitStep] = React.useState(0);
    const [confirmUpload, setConfirmUpload] = React.useState({});
    const apiClient = new ApiClient();
    const {fullUser} = React.useContext(User);

    const [isSelectOpen, setSelectOpen] = React.useState(false);

    const projectDirectoryClient = new ProjectDirectoryClient();
    const [nextProjectDirToken, setProjectDirToken] = React.useState("first"); 
    const load_new_projects_value = "load_new_projects"



    React.useEffect(() => {
        projectDirectoryClient.get_project_paginated().then(({data:p,nextToken:token}) => {
            console.log("projects")
            console.log(p)
            console.log("token")
            console.log(token)
            setProjects([{"ProjectName": "Nessuno"}, ...p]);
            setProjectDirToken(token);
        })
        userSvc.listUserEmails().then(e => setCCEmails(e));
    }, [userSvc]);

    const handleProjectChange = (event) => {
        const v = event.target.value
        if (v !== load_new_projects_value){
            setSelectedProject(event.target.value);
            setSelectOpen(false);
            }
        else{
            projectDirectoryClient.get_project_paginated(nextProjectDirToken).then(({data:p,nextToken:token}) => {
                console.log("projects")
                console.log(p)
                console.log("token")
                console.log(token)
                setProjects([...projects, ...p]);
                setProjectDirToken(token);

                setTimeout(() => {
                    setSelectOpen(true);
                });

                
            })
        }   
    };

    const handleInputChange = (_, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleAddCcValue = (event, newValue) => {
        // Avoid adding duplicates
        if (!ccValues.includes(newValue)) {
            setCcValues([...ccValues, newValue]);
        }
        setInputValue(''); // Clear input after selection
    };

    const handleCcDelete = (ccToDelete) => () => {
        setCcValues(ccValues.filter(cc => cc !== ccToDelete));
    };

    const fillCCPerProject = async ()=>{
        
        if (selectedProject === 'Nessuno') return setCcValues([]);
        setCcValues(
            (await projectDirectoryClient.get_project_participants(selectedProject)).participants.map(
                x=>x.email
            )
        )

    }

    React.useEffect(()=>{
        fillCCPerProject();
    },[selectedProject]);

    const handleFileUpload = (event) => {
        // Handle file upload
        console.log(event.target.files);
        setFile(event.target.files[0]);
    };

    const handleClickProjects = (event) => {
        setSelectOpen(!isSelectOpen);
    } 

    const handleTemplateChange = (e) => {
        setSelectedTemplate(e.target.value);
    }

    const handleSubmit = async (event) => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }
        setIsSubmit(true);
        setJobSubmitStep(1);
        event.preventDefault();
        const formData = {
            selectedProject,
            ccValues,
            fileName: file.name,
            selectedTemplate
        };
        
        console.log(formData);
        let prepareUploadResponse = await apiClient.do_prepare_upload(formData);
        setJobSubmitStep(2);
        let { jobId, url: presignedUrl } = prepareUploadResponse;
        console.log("Risposta prepare upload", prepareUploadResponse);
        console.log("Caricamento file", await uploadFileToS3ByPresignedUrl(file, presignedUrl));
        setJobSubmitStep(3);
        await new Promise(r => setTimeout(r, 2000));
        let confirmUploadResponse = await apiClient.do_confirm_upload({ jobId });
        console.log("Risposta confirm upload", confirmUploadResponse);
        setJobSubmitStep(4);
        setConfirmUpload(confirmUploadResponse);

    };

    return (
        <Stack sx={{ width: "100%" }} alignItems="center" justifyContent="space-evenly">

            {!isSubmit && (
                <Box sx={{ width: '100%', margin: 'auto', mt: 5, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="space-evenly">
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="project-select-label">Progetto</InputLabel>
                            
                            <Select
                                labelId="project-select-label"
                                id="project-select"
                                value={selectedProject}
                                label="Project"
                                onClick={handleClickProjects}
                                onChange={handleProjectChange}
                                open={isSelectOpen}
                            >
                                {projects.map((p, pI) => (
                                    <MenuItem key={pI} value={p.ProjectName}>{p.ProjectName}</MenuItem>

                                ))}
                                {!!nextProjectDirToken && <MenuItem key={"Carica altri progetti..."} value={load_new_projects_value}>Carica altri progetti...</MenuItem>}
                            </Select>
                            <FormHelperText>Tutti i membri di un progetto potranno visualizzare questo lavoro. Se desideri che il lavoro resti privato scegli 'Nessuno'.</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <Box sx={{ width: '100%', margin: 'auto', mt: 5 }}>

                                <Autocomplete
                                    value={null} // Value should be null to clear the input after selection
                                    inputValue={inputValue}
                                    onInputChange={handleInputChange}
                                    onChange={handleAddCcValue}
                                    options={ccEmails}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Add CC"
                                            variant="outlined"
                                        />
                                    )}
                                    freeSolo  // Allow arbitrary input not restricted to the options list
                                />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                                    {ccValues.map((cc, index) => (
                                        <Chip
                                            key={index}
                                            label={cc}
                                            onDelete={handleCcDelete(cc)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                            <FormHelperText>Questi indirizzi email riceveranno una copia del riassunto della riunione.</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="template-select-label">Template</InputLabel>
                                
                            <Select
                                    labelId="template-select-label"
                                    id="template-select"
                                    value={selectedTemplate}
                                    label="Template"
                                    onChange={handleTemplateChange}
                                >
                                    <MenuItem value={"template_IT.md"}>[IT] Template Base</MenuItem>
                                    <MenuItem value={"template_EN.md"}>[EN] Template Base</MenuItem>
                                    <MenuItem value={"EFC_template.md"}>Template EFC</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">

                            <Input
                                id="file-upload"
                                type="file"
                                inputProps={{ accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }}
                                onChange={handleFileUpload}
                                sx={{ mt: 2 }}
                                required={true}
                            />
                            <FormHelperText>Carica il file .docx della trascrizione</FormHelperText>
                        </FormControl>


                        <Button variant="contained" sx={{ mt: 3 }} onClick={handleSubmit} disabled={!file}>Crea Riassunto</Button>
                    </Stack>
                </Box>)}
            {isSubmit && (
                <>
                    {jobSubmitStep >= 1 && <Typography variant="h4" sx={{ mb: 2 }}>Avvio lavoro...</Typography>}
                    {/* {jobSubmitStep >= 2 && <Typography variant="h4" sx={{ mb: 2 }}>Caricamento pronto</Typography>}
                    {jobSubmitStep >= 3 && <Typography variant="h4" sx={{ mb: 2 }}>Caricamento eseguito!</Typography>} */}
                    {jobSubmitStep >= 4 && <>
                        <Typography variant="h4" sx={{ mb: 2 }}>Lavoro confermato!</Typography>
                        <Button variant="contained" sx={{ mt: 3 }} onClick={() => setIsSubmit(false)}>Crea un altro riassunto</Button>
                        <Button variant="contained" sx={{ mt: 3 }} onClick={() => setCurrentPage("jobList")}>Visualizza lavori richiesti</Button>
                    </>}
                </>
            )}
        </Stack>
    );
}
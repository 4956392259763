export async function uploadFileToS3ByPresignedUrl(file, presignedUrl) {
    try {
        let response = await fetch(presignedUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type
            },
            body: file
        });
        if (response.ok) {
            console.log('File uploaded successfully!');
        } else {
            console.error('Failed to upload file:', response.statusText);
        }
        return true;        
    } catch (error) {
        console.error('Error uploading file:', error);
        return false;
    }
}

// // Esempio di utilizzo
// const fileInput = document.getElementById('fileInput');
// const file = fileInput.files[0];
// const presignedUrl = 'URL_PRESIGNED_GENERATO_DALLA_FUNZIONE_LAMBDA';

// uploadFileToS3(file, presignedUrl);
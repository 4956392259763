import { fetchAuthSession } from 'aws-amplify/auth';

export default class ApiClient {


    constructor(baseUrl='https://api.meetings.platform.kicode.it'){
        this.baseUrl = baseUrl;
    }

    async get_jwt(){
        const currentUser = await fetchAuthSession();
        return currentUser.tokens.idToken.toString();
    }
    async do_get(path,query={}){

        // trasformo query da oggetto a stringa
        let queryString = '';
        for (const [key, value] of Object.entries(query)) {
            queryString += `${key}=${value}&`;
        }

        let resp =  await fetch(this.baseUrl + path + '?' + queryString, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + await this.get_jwt()
            }
        });
        return await resp.json();
    }

    async do_post(path, body){
        let resp = await fetch(this.baseUrl + path, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + await this.get_jwt(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return await resp.json();
    }
    async do_put(path, body){
        let resp = await fetch(this.baseUrl + path, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + await this.get_jwt(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return await resp.json();
    }

    async do_prepare_upload({ selectedProject = "Nessuno", ccValues = [], fileName, selectedTemplate }){
        return await this.do_post('/jobs', {
            selectedProject, ccValues, fileName, template:selectedTemplate
        });
        
    }
    async do_confirm_upload({ jobId }){
        return await this.do_put('/jobs', {
            jobId
        });
    }
    async do_list_jobs({project}){
        if (!project || project === 'Nessuno')
            return await this.do_get('/jobs');
        else 
            return await this.do_get('/jobs', {project})
    }

    async do_get_project_directory(){
        return await this.do_get('/projects');
    }

    async do_prepare_download({ jobId }){
        return await this.do_get('/jobs/'+jobId);
    }

}
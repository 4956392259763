import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import JobList from "../components/jobList/jobList";
import { User } from "../context";
// prova

import ProjectDirectoryClient from "../services/api/project_api.js";

//prova
const CustomComponent = ({ tabValue }) => {
  // Qui va implementata la logica per renderizzare la componente custom corretta
  return (
    <Paper style={{ padding: "20px" }}>
      Custom Component for Tab {tabValue}
    </Paper>
  );
};

const ListJobs = () => {
  const [tabValue, setTabValue] = useState("Nessuno");
  const [projects, setProjects] = useState(["Nessuno"]);

  const handleTabChange = (event, newValue) => {
    if (newValue === load_new_projects_value) {
      projectDirectoryClient
        .get_project_paginated(nextProjectDirToken)
        .then(({ data: p, nextToken: token }) => {
          setProjects([...projects, ...p]);
          setProjectDirToken(token);
        });
    } else {
      setTabValue(newValue);
    }
  };

  const { fullUser, isAdmin = false } = useContext(User);
  const projectDirectoryClient = new ProjectDirectoryClient();
  // const projSvc = useMemo(() => new ProjectService(), []);
  const [nextProjectDirToken, setProjectDirToken] = useState("first");
  const load_new_projects_value = "load_new_projects";

  useEffect(() => {
    // projSvc.listProjectsPerUser(fullUser?.tokens?.idToken?.payload?.email,isAdmin)
    // .then(prjs=>setProjects(['Nessuno',...prjs]));

    projectDirectoryClient
      .get_project_paginated()
      .then(({ data: p, nextToken: token }) => {
        setProjects([{ ProjectName: "Nessuno" }, ...p]);
        setProjectDirToken(token);
      });
  }, []);


  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography variant="h6">Sfoglia per progetto</Typography>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
        >
          {(projects || []).map((p) => (
            <Tab key={p} label={p.ProjectName} value={p.ProjectName} />
          ))}
          {!!nextProjectDirToken && (
            <Tab
              key={"Carica altri progetti..."}
              value={load_new_projects_value}
              label={"Carica altri progetti..."}
            />
          )}
        </Tabs>
      </Grid>
      <Grid item xs={10} sx={{ px: 5 }}>
        {tabValue && <JobList project={tabValue} />}
      </Grid>
    </Grid>
  );
};

export default ListJobs;
